import { ScreenContainer, Button, HeroSection, Hero, HeroBody, HeroTitle, HeroTeaser, HeroActionList, HeroAction, FeatureSection, FeatureList, Feature, FeatureImage, FeatureTitle, FeatureText } from "smooth-doc/components";
import * as React from 'react';
export default {
  ScreenContainer,
  Button,
  HeroSection,
  Hero,
  HeroBody,
  HeroTitle,
  HeroTeaser,
  HeroActionList,
  HeroAction,
  FeatureSection,
  FeatureList,
  Feature,
  FeatureImage,
  FeatureTitle,
  FeatureText,
  React
};