module.exports = [{
      plugin: require('../node_modules/smooth-doc/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"/vercel/path0/node_modules/smooth-doc/src/plugins/gatsby-remark-autolink-headers/index.js"},{"resolve":"gatsby-remark-images","options":{"maxWidth":1200}}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/vercel/path0"},
    },{
      plugin: require('../node_modules/smooth-doc/src/plugins/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"KhelJS documentation","short_name":"KhelJS documentation","start_url":"/","display":"minimal-ui","icon":"images/logo-manifest.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a1cf6445440dfca6db560de5dc9add27"},
    },{
      plugin: require('../node_modules/smooth-doc/gatsby-browser.js'),
      options: {"plugins":[],"name":"KhelJS documentation","description":"A framework to produces games using babylonjs","siteUrl":"https://example.com","sections":["About","Quick Start","Conceptual Guide","Programming Guide","Assets Guide","Inventory Guide","Game Play Guide","CLI Guide","Miscellaneous","Example","Publishing Games"]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    }]
